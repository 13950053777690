import {
  serviceConstant,
  userConstant,
  userServiceConstant,
} from "../action/constant";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case userServiceConstant.GET_USER_SERVICE:
      return action.payload;
    case userServiceConstant.FETCH_MORE_SERVICE:
      return [...state, ...action.payload];
    case userServiceConstant.SAVE_SERVICE:
      return state.map((data) => {
        if (data?.is_service_array) {
          return {
            ...data,
            services: data?.services?.map((service) => {
              if (service?._id == action.payload.id) {
                return { ...service, is_save: action.payload.status };
              } else {
                return service;
              }
            }),
          };
        } else {
          if (data?._id == action.payload.id) {
            return { ...data, is_save: action.payload.status };
          } else {
            return data;
          }
        }
      });
    case userServiceConstant.SAVE_CLAIM_SERVICE:
      return state.map((data) => {
        if (data._id == action.payload.id) {
          return { ...data, is_save: action.payload.status };
        } else {
          return data;
        }
      });
  }
  return state;
};
