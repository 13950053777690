import {claimConstants} from "../action/constant";

const initialState = {claimedProvider: [], contactedProvider: [], claimBookRequest: [], viewedProfile: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case claimConstants.CLAIM_PROVIDER:
            return {...state, claimedProvider: action.payload};
        case claimConstants.CLAIM_PROVIDER_CONTACT:
            return {...state, contactedProvider: action.payload};
        case claimConstants.CLAIM_PROVIDER_BOOK_REQUEST:
            return {...state, claimBookRequest: action.payload};
        case claimConstants.CLAIM_VIEW_COUNT:
            return {...state, viewedProfile: action.payload};
        case claimConstants.CLAIM_PROVIDER_SMS:
            return {...state, claimSms: action.payload};

    }
    return state;
};