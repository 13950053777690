import {walletConstant} from "../action/constant";

const initialState = {savedCars: [], cardlist: [], deletedCart: [], paymentHistory: [], refundRequest:[], bankInformation: [], saveBankDetails: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case walletConstant.SAVE_CARDS:
            return {...state, savedCars: action.payload};
        case walletConstant.CARD_LIST:
            return {...state, cardlist: action.payload};
        case walletConstant.DELETE_CART:
            return {...state, deletedCart: action.payload};
        case walletConstant.PAYMENT_HISTORY:
            return {...state, paymentHistory: action.payload};
        case walletConstant.REFUND_REQUEST:
            return {...state, refundRequest: action.payload};
        case walletConstant.GET_BANK_DETAILS:
            return {...state, bankInformation: action.payload};
        case walletConstant.SAVE_BANK_DETAILS:
            return {...state, saveBankDetails: action.payload};
    }
    return state;
};