import {saveConstant, userServiceConstant} from "../action/constant";


const initialState = {savedData: [], unsavedData: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case saveConstant.GET_SAVE_DATA:
            return {...state, savedData: action.payload};
        case userServiceConstant.SAVEUNSAVE_SERVICE:
            return {...state, unsavedData: action.payload};
    }
    return state;
};