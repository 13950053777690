import {paymentHistoryConstant} from "../action/constant";

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case paymentHistoryConstant.PAYMENT_HISTORY:
            return action.payload;
        case paymentHistoryConstant.FETCH_MORE_HISTORY:
            return [...state, ...action.payload];
    }
    return state;
};