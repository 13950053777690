import {paymentConstant} from "../action/constant";


const initialState = {paymentBookingData: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case paymentConstant.PAY_BOOKING:
            return {...state, paymentBookingData: action.payload};
    }
    return state;
};