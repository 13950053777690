import { languageConstant } from "../action/constant";

const initialState = 'en';

export default (state = initialState, action) => {
  switch (action.type) {
    case languageConstant.CHANGE_LANGUAGE:
      return action.payload;
  }
  return state;
};
