import {hireNcollaborateConstant, upcomingEventsConstant} from "../action/constant";

const initialState = {hireNcollaborated: [], addedHireNcollaborated: [], acceptedHireColla: [], hireNcollaRequest: [], cancledHireNcollaRequest: [], cancledHireNcollaModifyRequest: [], accepteHireNcollaModifyRequest: [], addedClaimHireNcollaborated: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case hireNcollaborateConstant.HIRE_N_COLLABORATE:
            return {...state, hireNcollaborated: action.payload};
        case hireNcollaborateConstant.ADD_HIRE_N_COLLABORATE:
            return {...state, addedHireNcollaborated: action.payload};
        case hireNcollaborateConstant.CLAIM_ADD_HIRE_N_COLLABORATE:
            return {...state, addedClaimHireNcollaborated: action.payload};
        case hireNcollaborateConstant.ACCEPT_HIRE_N_COLLABORATE:
            return {...state, acceptedHireColla: action.payload};
        case hireNcollaborateConstant.HIRE_N_COLLABORATE_REQ:
            return {...state, hireNcollaRequest: action.payload};
        case hireNcollaborateConstant.CANCLE_HIRE_N_COLLABORATE:
            return {...state, cancledHireNcollaRequest: action.payload};
        case hireNcollaborateConstant.CANCLE_HIRE_N_COLLABORATE_MODIFY:
            return {...state, cancledHireNcollaModifyRequest: action.payload};
        case hireNcollaborateConstant.ACCEPT_HIRE_N_COLLABORATE_MODIFY:
            return {...state, accepteHireNcollaModifyRequest: action.payload};
    }
    return state;
};
