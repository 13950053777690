export const userConstant = {
    GET_USER_DETAILS: "GET_USER_DETAILS",
};

export const languageConstant = {
    CHANGE_LANGUAGE : "CHANGE_LANGUAGE"
}

export const authConstant = {
    LOGGIN_USER: "LOGGIN_USER",
    LOGOUT: "LOGOUT",
};

export const saveConstant = {
    GET_SAVE_DATA: "GET_SAVE_DATA",
}
export const serviceConstant = {
    GET_SERVICE: "GET_SERVICE",
    LOGOUT: "LOGOUT",
    GET_PROVIDER_SERVICE: "GET_PROVIDER_SERVICE",
    SELECTED_USER_SERVICE: "SELECTED_USER_SERVICE",
    SELECTED_USER_LOCATION: "SELECTED_USER_LOCATION",
};

export const loaderConstant = {
    LOADER: "LOADER",
};
export const supportConstant = {
    ADD_SUPPORT: "ADD_SUPPORT",
};

export const userServiceConstant = {
    GET_USER_SERVICE: "GET_USER_SERVICE",
    FETCH_MORE_SERVICE: "FETCH_MORE_SERVICE",
    SAVE_SERVICE: "SAVE_SERVICE",
    SAVE_CLAIM_SERVICE: "SAVE_CLAIM_SERVICE",
    SAVEUNSAVE_SERVICE: "SAVEUNSAVE_SERVICE",
    SELECTED_USER_SERVICE: "SELECTED_USER_SERVICE",

};

export const otherProviderConstant = {
    GET_OTHER_PROVIDER_DETAILS: "GET_OTHER_PROVIDER_DETAILS",
    REMOVE_OTHER_PROVIDER_DETAILS: "REMOVE_OTHER_PROVIDER_DETAILS",
    ADD_RATING: "ADD_RATING",
};

export const scheduleConstant = {
    SCHEDULE_LIST: "SCHEDULE_LIST",
    DLELETE_SCHEDULE: "DLELETE_SCHEDULE",
    Add_SCHEDULE: "Add_SCHEDULE",
    EDIT_SCHEDULE: "EDIT_SCHEDULE",
}

export const myPageConstant = {
    GET_MY_PAGE_DETAILS: "GET_MY_PAGE_DETAILS",
};

export const myBookingConstant = {
    GET_MY_BOOKING_DETAILS: "GET_MY_BOOKING_DETAILS",
    GET_MY_UPCOMING_BOOKING_DETAILS: "GET_MY_UPCOMING_BOOKING_DETAILS",
    GET_MORE_MY_BOOKING_DETAILS: "GET_MORE_MY_BOOKING_DETAILS",
    GET_BOOKING_DETAILS: "GET_EVENT_BOOKING_DETAILS",
    DELETE_BOOKING_EVENT: "DELETE_BOOKING_EVENT",
    BOOKING_STATUS: "BOOKING_STATUS",
    CLEAR_BOOKING_STATUS: "CLEAR_BOOKING_STATUS"
};

export const sessionBookingConstant = {
    SESSION_BOOKING: "SESSION_BOOKING",
    MODIFY_SESSION_BOOKING: "MODIFY_SESSION_BOOKING",
    REMOVE_MODIFY_SESSION_BOOKING: "REMOVE_MODIFY_SESSION_BOOKING",
    REMOVE_SESSION_BOOKING: "REMOVE_SESSION_BOOKING"
}

export const upcomingEventsConstant = {
    GET_UPCOMING_EVENTS: "GET_UPCOMING_EVENTS",
    GET_UPCOMING_EVENTS_FOR_HIRE: "GET_UPCOMING_EVENTS_FOR_HIRE",
    SAVE_UNSAVE_EVENTS: "SAVE_UNSAVE_EVENTS"
}

export const paymentConstant = {
    PAY_BOOKING: 'PAY_BOOKING'
}
export const myEventConstant = {
    GET_MY_EVENT: 'GET_MY_EVENT',
    FATCH_MORE_MY_EVENT: 'FATCH_MORE_MY_EVENT',
    GET_EVENT_BY_ID: 'GET_EVENT_BY_ID',
    GET_EVENT_PROVIDERS: 'GET_EVENT_PROVIDERS',
    GET_MORE_EVENT_PROVIDERS: 'GET_MORE_EVENT_PROVIDERS',
    GET_EVENT_PARTICIPANTS: 'GET_EVENT_PARTICIPANTS',
    GET_MORE_EVENT_PARTICIPANTS: 'GET_MORE_EVENT_PARTICIPANTS',
    DELETE_EVENT: 'DELETE_EVENT',
    CANCEL_RAGISTER: 'CANCEL_RAGISTER',
    UPLOAD_PACKAGE_IMAGE: 'UPLOAD_PACKAGE_IMAGE',
    CREATE_EVENT: 'CREATE_EVENT',
    EDIT_EVENT: 'EDIT_EVENT',
}

export const hireNcollaborateConstant = {
    HIRE_N_COLLABORATE: "HIRE_N_COLLABORATE",
    ADD_HIRE_N_COLLABORATE: "ADD_HIRE_N_COLLABORATE",
    CLAIM_ADD_HIRE_N_COLLABORATE: "CLAIM_ADD_HIRE_N_COLLABORATE",
    ACCEPT_HIRE_N_COLLABORATE: "ACCEPT_HIRE_N_COLLABORATE",
    CANCLE_HIRE_N_COLLABORATE: "CANCLE_HIRE_N_COLLABORATE",
    CANCLE_HIRE_N_COLLABORATE_MODIFY: "CANCLE_HIRE_N_COLLABORATE_MODIFY",
    ACCEPT_HIRE_N_COLLABORATE_MODIFY: "ACCEPT_HIRE_N_COLLABORATE_MODIFY",
    HIRE_N_COLLABORATE_REQ: "HIRE_N_COLLABORATE_REQ"
}
export const notificationConstant = {
    ALL_NOTIFICATION: "ALL_NOTIFICATION",
    FATCH_MORE_NOTIFICATION: 'FATCH_MORE_NOTIFICATION',
    NOTIFICATION_SEEN: "NOTIFICATION_SEEN",
    NOTIFICATION_STATUS: "NOTIFICATION_STATUS",
    GET_EVENTS: "GET_EVENTS",
    DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
}

export const paymentHistoryConstant = {
    PAYMENT_HISTORY: "PAYMENT_HISTORY",
    FETCH_MORE_HISTORY: "FETCH_MORE_HISTORY",
    GET_WALLET_DATA: "GET_WALLET_DATA",
}

export const calenderConstant = {
    BOOK_SCHEDULE_DATE: "BOOK_SCHEDULE_DATE",
    MY_SCHEDULE_DATE: "MY_SCHEDULE_DATE",
    REMOVE_MY_SCHEDULE_DATE: "REMOVE_MY_SCHEDULE_DATE",
    SCHEDULE_LIST: "SCHEDULE_LIST",
    GET_SYSTEM_SERVICE_CHARGE: "GET_SYSTEM_SERVICE_CHARGE"
}

export const claimConstants = {
    CLAIM_PROVIDER: "CLAIM_PROVIDER",
    CLAIM_PROVIDER_CONTACT: "CLAIM_PROVIDER_CONTACT",
    CLAIM_PROVIDER_BOOK_REQUEST: "CLAIM_PROVIDER_BOOK_REQUEST",
    CLAIM_VIEW_COUNT: "CLAIM_VIEW_COUNT",
    CLAIM_PROVIDER_SMS: 'CLAIM_PROVIDER_SMS'
}

export const refDescountConstants = {
    REFF_CODE_LIST: "REFF_CODE_LIST",
    GENERATE_CODE:"GENERATE_CODE",
    CHECK_REF_CODE: "CHECK_REF_CODE"
}

export const walletConstant = {
    SAVE_CARDS: "SAVE_CARDS",
    CARD_LIST: "CARD_LIST",
    PAYMENT_HISTORY: "PAYMENT_HISTORY",
    DELETE_CART: "DELETE_CARD",
    REFUND_REQUEST: 'REFUND_REQUEST',
    GET_BANK_DETAILS: 'GET_BANK_DETAILS',
    SAVE_BANK_DETAILS: 'SAVE_BANK_DETAILS'
}

export const event = {
    GET_EVENTS: "GET_EVENTS",
    CHECK_MODIFY_PAYMENT: "CHECK_MODIFY_PAYMENT",
    REMOVE_CHECK_MODIFY_PAYMENT: "REMOVE_CHECK_MODIFY_PAYMENT",
    BOOK_EVENT: "BOOK_EVENT",
    EVENT_NOTIFY: "EVENT_NOTIFY",
    REMOVE_EVENT: "REMOVE_EVENT",
    MODIFY_EVENT: "MODIFY_EVENT",
    REMOVE_MODIFY_EVENT: "REMOVE_MODIFY_EVENT",
    ACCEPT_EVENT_NOTIFICATION: 'ACCEPT_EVENT_NOTIFICATION'
}
export const discountConstant = {
    CHECK_DISCOUNT_CODE: "CHECK_DISCOUNT_CODE",
    CLEAN_DISCOUNT_CODE: 'CLEAN_DISCOUNT_CODE',
    DISCOUNT_CODE_LISTING: "DISCOUNT_CODE_LISTING",
    PROVIDER_EVENT_LIST: "PROVIDER_EVENT_LIST",
    PROVIDER_SERVICE_LIST: "PROVIDER_SERVICE_LIST",
    ADD_DISCOUNT_CODE: "ADD_DISCOUNT_CODE",
    EDIT_DISCOUNT_CODE: "EDIT_DISCOUNT_CODE",
    DELETE_DISCOUNT_CODE: "DELETE_DISCOUNT_CODE",
}

export const version = {
    UPDATE_VERSION: 'UPDATE_VERSION',
    RADIUS: 'RADIUS'
}