import {scheduleConstant} from "../action/constant";


const initialState = {scheduleList: [], deletedSchedule: [], addedSchedule: [], editedSchedule: [],};

export default (state = initialState, action) => {
    switch (action.type) {
        case scheduleConstant.SCHEDULE_LIST:
            return {...state, scheduleList: action.payload};
        case scheduleConstant.DLELETE_SCHEDULE:
            return {...state, deletedSchedule: action.payload};
        case scheduleConstant.Add_SCHEDULE:
            return {...state, addedSchedule: action.payload};
        case scheduleConstant.EDIT_SCHEDULE:
            return {...state, editedSchedule: action.payload};
    }
    return state;
};