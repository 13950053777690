import {discountConstant} from "../action/constant";

const initialState = {checkCodeDetails: [], discountList: [], eventList: [], serviceList: [], addedDiscount: [], deletedDiscount: [], editedDiscount: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case discountConstant?.CHECK_DISCOUNT_CODE:
            return {...state, checkCodeDetails: action.payload};
        case discountConstant.CLEAN_DISCOUNT_CODE:
            return {...state, checkCodeDetails: action.payload};
        case discountConstant.DISCOUNT_CODE_LISTING:
            return {...state, discountList: action.payload};
        case discountConstant.PROVIDER_EVENT_LIST:
            return {...state, eventList: action.payload};
        case discountConstant.PROVIDER_SERVICE_LIST:
            return {...state, serviceList: action.payload};
        case discountConstant.ADD_DISCOUNT_CODE:
            return {...state, addedDiscount: action.payload};
        case discountConstant.DELETE_DISCOUNT_CODE:
            return {...state, deletedDiscount: action.payload};
        case discountConstant.EDIT_DISCOUNT_CODE:
            return {...state, editedDiscount: action.payload};
    }
    return state;
};
