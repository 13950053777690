import {calenderConstant} from "../action/constant";

const initialState = {bookedDate: [], myBookedDate:[], scheduleList: [], systemCharge: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case calenderConstant.BOOK_SCHEDULE_DATE:
            return {...state, bookedDate: action.payload};
        case calenderConstant.MY_SCHEDULE_DATE:
            return {...state, myBookedDate: action.payload};
        case calenderConstant.REMOVE_MY_SCHEDULE_DATE:
            return {...state, myBookedDate: action.payload};
        case calenderConstant.SCHEDULE_LIST:
            return {...state, scheduleList: action.payload};
        case calenderConstant.GET_SYSTEM_SERVICE_CHARGE:
            return {...state, systemCharge: action.payload};
    }
    return state;
};
