import { paymentHistoryConstant } from "../action/constant";

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case paymentHistoryConstant.GET_WALLET_DATA:
      return action.payload;
  }
  return state;
};
