import { refDescountConstants} from "../action/constant";


const initialState = {refCodeList: [], generatedCode: [], userRefCodeData: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case refDescountConstants.REFF_CODE_LIST:
            return {...state, refCodeList: action.payload};
        case refDescountConstants.GENERATE_CODE:
            return {...state, generatedCode: action.payload};
        case refDescountConstants.CHECK_REF_CODE:
            return {...state, userRefCodeData: action.payload};
    }
    return state;
};