import {upcomingEventsConstant} from "../action/constant";

const initialState = {upComingEvents: [], savedUnsaved: [], upComingEventsForHire: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case upcomingEventsConstant.GET_UPCOMING_EVENTS:
            return {...state, upComingEvents: action.payload};
        case upcomingEventsConstant.GET_UPCOMING_EVENTS_FOR_HIRE:
            return {...state, upComingEventsForHire: action.payload};
        case upcomingEventsConstant.SAVE_UNSAVE_EVENTS:
            return {...state, savedUnsaved: action.payload};
    }
    return state;
};
