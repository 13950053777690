import {myEventConstant} from "../action/constant";


const initialState = {myEventData: [], eventData: [], eventProvider: [], eventParticipant: [], deletedEvent: [],canceldRegister: [], uploadedPackageImage: [], createdEvent: [], editedEvent: []};

export default (state = initialState, action) => {
    // console.log(action?.payload?.provider_detail, "action?.payload")
    switch (action.type) {
        case myEventConstant.GET_MY_EVENT:
            return {...state, myEventData: action.payload};
        case myEventConstant.FATCH_MORE_MY_EVENT:
            return {...state, myEventData: [...state.myEventData, ...action.payload]};
        case myEventConstant.GET_EVENT_BY_ID:
            return {...state, eventData: action.payload};
        case myEventConstant.GET_EVENT_PROVIDERS:
            return {...state, eventProvider: action.payload};
        case myEventConstant.GET_MORE_EVENT_PROVIDERS:
            return {...state, eventProvider: {...state.eventProvider, provider_detail: [...state.eventProvider?.provider_detail,...action?.payload?.provider_detail]}};
        case myEventConstant.GET_EVENT_PARTICIPANTS:
            return {...state, eventParticipant: action.payload};
        case myEventConstant.GET_MORE_EVENT_PARTICIPANTS:
            return {...state, eventParticipant: [...state.eventParticipant, ...action.payload]};
        case myEventConstant.DELETE_EVENT:
            return {...state, deletedEvent: action.payload};
        case myEventConstant.CANCEL_RAGISTER:
            return {...state, deletedEvent: action.payload};
        case myEventConstant.UPLOAD_PACKAGE_IMAGE:
            return {...state, uploadedPackageImage: action.payload};
        case myEventConstant.CREATE_EVENT:
            return {...state, createdEvent: action.payload};
        case myEventConstant.EDIT_EVENT:
            return {...state, editedEvent: action.payload};
    }
    return state;
};