import {myBookingConstant, sessionBookingConstant} from "../action/constant";

const initialState = {sessionBooked: [], myBookingDetails: [], bookingDetails: [], deletedEvent: [], bokingStatus: [], modifyedBooking: []};

export default (state = initialState, action) => {
  switch (action.type) {
    case myBookingConstant.GET_MY_BOOKING_DETAILS:
      return {...state, myBookingDetails: action.payload};
    case myBookingConstant.GET_MORE_MY_BOOKING_DETAILS:
      return {...state, myBookingDetails: [...state.myBookingDetails, ...action.payload]};
    case sessionBookingConstant.SESSION_BOOKING:
      return {...state, sessionBooked: action.payload};
    case sessionBookingConstant.MODIFY_SESSION_BOOKING:
      return {...state, modifyedBooking: action.payload};
    case sessionBookingConstant.REMOVE_SESSION_BOOKING:
      return {...state, sessionBooked: action.payload};
    case sessionBookingConstant.REMOVE_MODIFY_SESSION_BOOKING:
      return {...state, modifyedBooking: action.payload};
    case myBookingConstant.GET_BOOKING_DETAILS:
      return {...state, bookingDetails: action.payload};
    case myBookingConstant.DELETE_BOOKING_EVENT:
      return {...state, deletedEvent: action.payload};
    case myBookingConstant.BOOKING_STATUS:
      return {...state, bokingStatus: action.payload};
    case myBookingConstant.CLEAR_BOOKING_STATUS:
      return {...state, bokingStatus: action.payload};
  }
  return state;
};
