import {version} from "../action/constant";

const initialState = {updatedVersion: [], radius: 22};

export default (state = initialState, action) => {
    switch (action.type) {
        case version.UPDATE_VERSION:
            return {...state, updatedVersion: action.payload};
        case version.RADIUS:
            return {...state, radius: action.payload};

    }
    return state;
};