import {claimConstants, supportConstant} from "../action/constant";

const initialState = {supportNhelp: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case supportConstant.ADD_SUPPORT:
            return {...state, supportNhelp: action.payload};

    }
    return state;
};