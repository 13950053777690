import {notificationConstant, userServiceConstant} from "../action/constant";

const initialState = {notificationList: [], seenNotification: "", event: [], deletedNotification: []};

export default (state = initialState, action) => {

    switch (action.type) {
        case notificationConstant.ALL_NOTIFICATION:
            return {...state, notificationList: action.payload};
        case notificationConstant.FATCH_MORE_NOTIFICATION:
            return {...state, notificationList: [...state.notificationList, ...action.payload]};
        case notificationConstant.NOTIFICATION_SEEN:
            return {...state, seenNotification: action.payload};
        case notificationConstant?.GET_EVENTS:
            return {...state, event: action.payload};
        case notificationConstant?.DELETE_NOTIFICATION:
            return {
                ...state, notificationList: state.notificationList?.filter((ele) => {
                    if (ele?._id !== action?.payload?._id) {
                        return ele
                    }
                })
            };
        case notificationConstant.NOTIFICATION_STATUS:
            return {
                ...state, notificationList: state?.notificationList?.map((ele) => {
                    if (ele?._id == action.payload) {
                        return {...ele, is_seen: true}
                    } else {
                        return ele
                    }
                })
            };
    }
    return state;
};
