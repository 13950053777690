import { serviceConstant, userConstant } from "../action/constant";

const initialState = { serviceList: [], userService: [], selectedService: [], selectedLocation: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case serviceConstant.GET_SERVICE:
      return { ...state, serviceList: action.payload };
    case serviceConstant.GET_PROVIDER_SERVICE:
      return { ...state, userService: action.payload };
    case serviceConstant.SELECTED_USER_SERVICE:
      return { ...state, selectedService: action.payload };
    case serviceConstant.SELECTED_USER_LOCATION:
      return { ...state, selectedLocation: action.payload };
  }
  return state;
};
